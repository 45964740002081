.table-bordered-2 {
  thead,
  tbody,
  tfoot,
  tr,
  td,
  th {
    border-color: #e4e6ef !important;
    border-style: solid !important;
    border-width: 1px !important;
  }
}

.table-react {
  display: inline-block;
  border-spacing: 0;
  width: 100%;

  .tr {
    // width: auto;
    // justify-content: space-between;
    // width: 100% !important;
    :last-child {
      .td {
        border-bottom: 0;
      }
    }
  }

  .th,
  .td {
    margin: 0;
    padding: 0.5rem;
    position: relative;

    :last-child {
      border-right: 0;
    }

    .resizer {
      display: inline-block;
      // background: blue;
      width: 10px;
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
      transform: translateX(50%);
      z-index: 1;
      touch-action: none;

      &.isResizing {
        background: rgb(151, 151, 151);
      }
    }
  }
}

// .table-static {
//   position: relative;
//   th {
//     position: sticky;
//     left: 0;
//     top: 0;
//     z-index: 9999999999;
//   }
// }

.headcol-right {
  position: sticky;
  width: 100px;
  right: 0;
  z-index: 10;
  background: var(--kt-table-bg) !important;
}

.rowcol-right {
  position: sticky;
  width: 100px;
  right: 0;
  z-index: 10;
  // background: $white !important;
}

.headcol-left {
  position: sticky;
  width: 100px;
  top: 0;
  left: 0;
  z-index: 100;
  background: #f5f1e0 !important;
}

.rowcol-left {
  position: sticky;
  width: 130px;
  left: 0;
  z-index: 10;
  background: rgb(247 246 246) !important;
}

.headcol-left:nth-child(2) {
  left: 130px;
}

.rowcol-left:nth-child(2) {
  left: 130px;
}

.headcol-left:nth-child(3) {
  left: 260px;
}

.rowcol-left:nth-child(3) {
  left: 260px;
}

// .scoll-flipped,
// .scoll-flipped .scoll-content {
//   transform: rotateX(180deg);
//   -ms-transform: rotateX(180deg); /* IE 9 */
//   -webkit-transform: rotateX(180deg); /* Safari and Chrome */
// }

.headcol-top {
  position: sticky;
  width: 100px;
  // z-index: 10;
  top: 0;
  background: #ffd000 !important;
}

.sticky {
  background: white;
  position: sticky;
  top: 0;
}

.table-static {
  display: table;
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;

  .tr {
    display: table-row;
  }

  .thead {
    display: table-header-group;
  }

  .td,
  .th {
    display: table-cell;
    padding: 0.75rem;
    vertical-align: top;
  }

  .tfoot {
    display: table-footer-group;
    font-weight: bold;
  }

  .tbody {
    display: table-row-group;
  }
}

@each $name, $value in $theme-colors-dark {
  @if (theme-light-color($name)) {
    .headcol-right {
      background-color: var(--kt-#{$name}) !important;
    }
    .headcol-left {
      background-color: var(--kt-#{$name}) !important;
    }
  }

  @if (theme-light-color($name)) {
    .rowcol-right {
      background-color: var(--kt-#{$name}-light) !important;
    }
    .rowcol-left {
      background-color: var(--kt-#{$name}-light) !important;
    }
  }
}
