.border-right {
    border-right: 1px solid black;
}

.border {
    border: 2px solid black;
}


@media screen and (max-width: 700px)  {
    .border-right{
        border: none;
    }
}